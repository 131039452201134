<template>
  <div class="Home">
    <page-header/>
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-text-left" uk-grid>
          <div class="uk-width-auto">
            <h4 class="qList">{{ $t("orgaExam.results.eName") }} :
              {{ examDetail.examName }}</h4>
          </div>
        </div>
      </div>
      <div class="uk-container uk-margin-top">
        <div class="uk-card uk-card-default uk-box-shadow-medium uk-overflow-auto">
          <header class="uk-card-header"
                  style="border-top: 3px solid rgb(1, 176, 255) !important; padding: 10px 20px;">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small">
                <button type="button" class="uk-button uk-button-primary uk-margin-small-right"
                        @click="setExamCode()">
                  <span uk-icon="icon: plus" class="uk-margin-small-right"></span>{{ $t("addStudent") }}
                </button>
                <span class="uk-flex uk-flex-middle uk-margin-right">{{ $t("show") }}</span>
                <div uk-form-custom="target: > * > span:first-child">
                  <select v-model="size" @change="listCount()" class="sortBy uk-align-left">
                    <option :value="25">25</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                    <option :value="500">500</option>
                  </select>
                  <button
                      class="uk-button uk-button-default btn-sortBy"

                      tabindex="-1">

                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
                <span
                    class="uk-flex uk-flex-middle uk-margin-left"
                > {{ $t("entries") }}</span>
              </div>
              <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle">
                <div uk-form-custom="target: > * > span:first-child">
                  <div style="min-width: 200px">
                    <input style="height: 40px;cursor: pointer" readonly type="text"
                           :value="activeGroup.groupName"
                           placeholder="Select group"
                           class="uk-width-1-1 uk-select">
                    <div style="padding: 0px 10px;"
                         data-uk-dropdown="mode:click; pos: bottom-justify">
                      <div class="uk-search uk-search-default uk-width-1-1">
                        <span class="uk-search-icon-flip" uk-search-icon></span>
                        <input class="uk-search-input" v-model="value" type="search"
                               :placeholder="$t('search')">
                      </div>
                      <ul style="max-height: 150px;overflow-y: auto;"
                          class="uk-nav uk-nav-default" v-if="resultQuery.length > 0">
                        <template v-for="(item, index) in resultQuery">
                          <li :key="index"
                              :class="{ 'uk-active': item.id == activeGroup.id }">
                            <a @click="selectGroup(item)" href="javascript:void(0)">{{ item.groupName }}</a>
                          </li>
                        </template>
                      </ul>
                      <ul class="uk-nav uk-nav-default uk-padding-small" v-else>
                        <li class="uk-text-center">Empty.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <form @submit.prevent="searchFilter()"
                      class="uk-search uk-search-default uk-margin-left">
                  <input @keyup="searchKeyup" class="uk-search-input" type="search" v-model="filter"
                         :placeholder="$t('search')">
                </form>
                <button type="button"
                        class="uk-button uk-button-default uk-button-small uk-padding-remove uk-margin-left"
                        style="background: #01b0ff;">
                  <span uk-icon="search" class="searchIcon" @click="searchFilter()"></span>
                </button>
                <button type="button"
                        class="uk-button uk-button-default uk-button-small uk-padding-remove uk-margin-small-left"
                        style="background: #01b0ff;">
                  <span uk-icon="refresh" class="searchIcon" @click="defaultSetting()"></span>
                </button>
              </div>
            </div>
          </header>
          <div class="uk-card-body uk-padding-remove">
            <table class="uk-table uk-table-responsive uk-table-small">
              <thead style="background: #01b0ff">
              <tr class="col" style="border-top: none; border-bottom: none">
                <th class="uk-text-center">
                  <input class="uk-checkbox" type="checkbox" @change="checkallchange"
                         v-model="checkall" value="false"/>
                </th>
                <th class="uk-text-center">{{ $t('image') }}
                </th>
                <th style="cursor: pointer" class="uk-text-center" @click="order('first_name')">{{ $t('name') }}
                  <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                       v-if="orderdata.field == 'first_name' && orderdata.ascordesc == 'asc'"/>
                  <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                       v-else-if="orderdata.field == 'first_name' && orderdata.ascordesc == 'desc'"/>
                  <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                </th>
                <!--                                <th style="cursor: pointer" class="uk-text-center" @click="order('last_name')">Last name-->
                <!--                                    <img src="../../assets/icons/asc.png" alt="" width="16" height="16"-->
                <!--                                         v-if="orderdata.field == 'last_name' && orderdata.ascordesc == 'asc'"/>-->
                <!--                                    <img src="../../assets/icons/desc.png" alt="" width="16" height="16"-->
                <!--                                         v-else-if="orderdata.field == 'last_name' && orderdata.ascordesc == 'desc'"/>-->
                <!--                                    <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>-->
                <!--                                </th>-->
                <th style="cursor: pointer" class="uk-text-center" @click="order('login_id')">{{ $t('ID') }}
                  <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                       v-if="orderdata.field == 'login_id' && orderdata.ascordesc == 'asc'"/>
                  <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                       v-else-if="orderdata.field == 'login_id' && orderdata.ascordesc == 'desc'"/>
                  <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                </th>

                <th @click="order('created_at')" class="uk-text-center" style="cursor: pointer">
                  {{ $t('createAt') }}
                  <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                       v-if="orderdata.field == 'created_at' && orderdata.ascordesc == 'asc'"/>
                  <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                       v-else-if="orderdata.field == 'created_at' && orderdata.ascordesc == 'desc'"/>
                  <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                </th>
                <th class="uk-text-center">{{ $t('examCode') }}</th>
              </tr>
              </thead>

              <tbody v-if="students.length > 0">
              <tr v-for="(item, index) in students" :key="index" :item="item">
                <td>
                  <input v-if="item.exam_code.length == 0"
                         v-model="item.checked" class="uk-checkbox"
                         type="checkbox"/></td>
                <td>
                  <img v-if="item.image.length > 0"
                       :src="`/uploadingDir/${item.image}`" width="50"
                       :alt="item.image"/>
                  <img v-else width="45" src="../../assets/user_pro.png"/>
                </td>

                <td>
                  {{ item.first_name }}
                </td>
                <!--                                <td>-->
                <!--                                    {{item.last_name}}-->
                <!--                                </td>-->
                <td>
                  {{ item.username }}
                </td>
                <td>{{ item.created_at | moment }}</td>
                <td>
                  <div v-if="item.exam_code.length > 0" class="uk-button-group">
                    <button type="button" disabled
                            class="uk-button uk-button-default uk-width-auto uk-button-small">
                      {{ item.exam_code }}
                    </button>
                    <button type="button" @click="removeExamCode(item)"
                            class="uk-button uk-button-danger uk-width-auto uk-button-small"><span
                        uk-icon="icon: trash; ratio: 1.0;"></span></button>
                  </div>

                </td>
              </tr>
              </tbody>

              <tbody v-else>
              <tr>
                <td colspan="24">Empty.</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="uk-card-footer uk-text-center">
            <div class="uk-flex uk-flex-center">
              <v-pagination
                  v-model="currentPage"
                  :page-count="pages"
                  :classes="uikitClasses"
                  :labels="Labels"
                  @change="onChange"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>


    <page-footer/>
  </div>

</template>
<script>
import {mapGetters} from "vuex"
import GroupService from '@/services/GroupService'
import ExamService from '@/services/ExamService'
import moment from "moment"
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
import vPagination from "@/components/globals/vue-plain-pagination"

export default {
  name: "StudentExamMapping",
  components: {
    vPagination,
    PageHeader,
    PageFooter
  },
  data() {
    return {
      checkall: false,
      examDetail: {},
      currentPage: 1,
      pages: 0,
      examId: 0,
      size: 25,
      uikitClasses: {
        ul: "uk-pagination",
        li: "",
        liActive: "uk-active",
        liDisable: "uk-disabled",
        button: "page-link"
      },
      Labels: {
        first: "<span uk-icon='chevron-double-left'></span>",
        prev: "<span uk-pagination-previous></span>",
        next: "<span uk-pagination-next></span>",
        last: "<span uk-icon='chevron-double-right'></span>"
      },
      itemsPerPage: 1,
      value: "",
      stGroups: [{groupName: "All group", id: 0}],
      activeGroup: {groupName: "All group", id: 0},
      filter: "",
      students: [],
      orderdata: {
        field: 'first_name',
        ascordesc: 'asc'
      }

    };
  },
  created() {
  },
  mounted() {
    this.examId = this.$route.params.examId;
    this.examDetails();
    this.groups()
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD - HH:mm:ss')
      // return moment(date).format('YYYY-MM-DD, h:mm:ss')
    }
  },
  methods: {
    order(field) {
      if (field != this.orderdata.field) {
        this.orderdata.field = field;
        this.orderdata.ascordesc = 'asc'
      } else {
        this.orderdata.ascordesc = (this.orderdata.ascordesc == 'asc' ? 'desc' : 'asc')
      }
      this.loaddata(this.currentPage)
    },
    searchKeyup() {
      if (this.filter.length == 0) {
        this.searchFilter()
      }
    },
    searchFilter() {
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    defaultSetting() {
      window.location.reload(true);
    },
    async removeExamCode(item) {
      if (window.confirm("Do you want remove " + item.email + " user from this exam?")) {
        try {
          if (item.exam_code.length == 0) {
            return
          }
          const response = await GroupService.removeExamCode({
            orgId: this.currentUser.organizs[0].organizId,
            examId: this.examId,
            examCode: item.exam_code,
            uId: item.id
          });
          if (response.data.success) {
            item.exam_code = "";
            this.examDetails()
          } else {
            alert(response.data.message)
          }
        } catch (error) {
          alert(error.response.data.message)
        }
      }
    },
    async setExamCode() {
      let sts = [];
      this.students.map((item) => {
        if (item.checked) {
          sts.push({id: item.id, name: item.first_name})
        }
      });
      if (sts.length == 0) {
        alert("please check student");
        return
      }

      if (window.confirm("Are you sure to give the exam code to selected users?")) {
        try {
          const response = await GroupService.setExamCode({
            orgId: this.currentUser.organizs[0].organizId,
            examId: this.examId,
            students: sts
          });

          if (response.data.success) {
            this.loaddata();
            this.examDetails();
            this.checkall = false
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    checkallchange() {
      this.students.map((item) => {
        if (item.exam_code.length == 0) {
          item.checked = this.checkall
        }
      })
    },
    async groups() {
      try {
        const response = await GroupService.groups(
            {orgid: this.currentUser.organizs[0].organizId});
        this.stGroups = [{groupName: "All group", id: 0}];
        response.data.map((item) => {
          this.stGroups.push(item)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async examDetails() {
      try {
        this.examDetail = {};
        const response = await ExamService.getExamDetails(this.examId,{ grpId: this.activeGroup.id, orgId : this.currentUser.organizs[0].organizId} );
        if (response.data.status == 200) {
          this.examDetail = response.data.result.examDetail
        }
      } catch (error) {
        console.log(error)
      }
    },
    listCount() {
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    selectGroup(item) {
      if (this.activeGroup.id != item.id) {
        this.activeGroup = item;
        this.currentPage = 1;
        this.loaddata(this.currentPage)
      }

    },
    async loaddata(page) {
      try {
        if (page > 0) {
          page = page - 1;
        }

        let item = {
          examId: this.examId,
          page: page,
          size: this.size,
          sortfield: this.orderdata.field,
          ascordesc: this.orderdata.ascordesc,
          orgId: this.currentUser.organizs[0].organizId,
          grpId: this.activeGroup.id,
          filter: this.filter
        };
        const response = await GroupService.groupstudents(item);
        if (response.status == 200) {
          response.data.content.map((item) => {
            item.checked = false
          });
          this.students = response.data.content;
          this.pages = response.data.totalPages;
          this.totalElements = response.data.totalElements
        }
      } catch (error) {
        console.log(error);
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onChange: function () {
      this.loaddata(this.currentPage);
    },
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "currentUser"]),
    resultQuery() {
      if (this.value) {
        return this.stGroups.filter((item) => {
          return this.value.toLowerCase().split(' ').every(v => item.groupName.toLowerCase().includes(v))
        })
      } else {
        return this.stGroups;
      }
    }
  }
};
</script>
<style scoped>

.uk-nav-default > li > a {
  display: block;
  padding: 5px;
  font-size: 14px;
  margin: 5px 0px;
}

.uk-nav-default > li.uk-active > a {
  background-color: rgb(1, 176, 255);
  color: #ffffdd;
}

table tr .hidden {
  display: none;
}

.uk-table td {
  padding: 7px 12px;
}

.uk-table-small th, .uk-table-small td {
  padding: 10px 5px;
}


.sortBy {
  opacity: 0;
}

.btn-sortBy {
  outline: none;
  padding: 0px 5px 0px 5px;
}

.uk-label.uk-label-primary {
  background-color: #01b0ff
}

.uk-label {
  padding: 5px 10px;
}

.uk-button-primary {
  background-color: #01b0ff
}

.uk-table td.subtit {
  border: 1px solid #dee2e6;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
  font-size: 14px;
}

.sortBy {
  opacity: 0;
}

.btn-sortBy {
  outline: none;
  padding: 0px 5px 0px 5px;
}

.uk-table tr {
  border: 1px solid #dee2e6;
}

.uk-table td.subtits {
  border: 1px solid #dee2e6;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
  padding-left: 10px;
  /* color: #404348; */
  font-size: 14px;
  /* background-color: #eeeeef; */
}

.uk-table tr td {
  vertical-align: middle;
}

.uk-table tr.col {
  border-bottom: 1px solid #dee2fe;
  vertical-align: middle;
  border-top: 1px solid #dee2fe;
  font-size: 13px;
}

.uk-table th {
  vertical-align: middle;
  color: #fff;
}

.qList {
  height: 20px;
  font-size: 1.4em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

.tit {
  color: #0276ac;
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.tit2 {
  text-align: left;
  font-size: 14px;
}

.searchIcon >>> svg {
  width: 28px;
  margin: 5px;
  color: #fff;
  background: #01b0ff;
}
</style>
